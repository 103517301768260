import { Layout, Col, Table } from "antd";
import { CloseOutlined, BorderOutlined, CheckSquareOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import Comments from "./cell/Comment";
import { IPromotion } from '../../interfaces/promotion.interface';
import { StoresCell } from './cell/StoresCell';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { FilterFilled } from '@ant-design/icons'; 
import { useSelector } from "react-redux";
import { RootState } from "../../reduxStore/reducers/rootReducer";
import '../../styles/responsive-table.css';

const { Content } = Layout;

const PromotionsTable = (
    {
        promotionsData,
        pdfSignClickHandler,
        commentsSubmitHandler,
        genericAltSignClickHandler
    }: {
        promotionsData: IPromotion[],
        pdfSignClickHandler: Function,
        commentsSubmitHandler: Function,
        genericAltSignClickHandler: Function
    }) => {

    const readOnlyState = useSelector(
        (state: RootState) => state.readOnly
    )

    const signKitColorsState = useSelector(
        (state: RootState) => state.signKitColors.colors
    )


    const getUniqueValuesComma = (data: any[], key: string | number) => {

        const uniqueValues = new Set();
        data.forEach(item => {
            if (Array.isArray(item[key])) {
                item[key].forEach((value: any) => uniqueValues.add(value));
            } else if (typeof item[key] === 'string') {
                item[key].split(',').forEach((value: string) => uniqueValues.add(value.trim()));
            } else {
                uniqueValues.add(item[key]);
            }
        });
        return Array.from(uniqueValues).map(value => ({
            text: value,
            value: value,
        }));
    };

    const columns: ColumnsType<IPromotion> = [
        {
            title: "Brand",
            dataIndex: "brand",
            key: "brand",
            width: "10em",
            filters: getUniqueValuesComma(promotionsData, 'brand') as FilterDropdownProps['filters'],
            filterIcon: filtered => <FilterFilled style={{ color: filtered ?  '#fff':'#808080' }} />, 
            sorter: (a: IPromotion, b: IPromotion) => a.brand.join(',').localeCompare(b.brand.join(',')),
            onCell: (_record, _rowIndex) => {
                return {
                    style: { padding: "8px" }
                };
            },
            render: (_, { brand }) => (
                brand.join(',')
            ),
            onFilter: (value, record) => String(record.brand).split(',').map(v => v.trim()).includes(String(value)),
        },
        {
            title: "SBU",
            dataIndex: "sbu",
            key: "sbu",
            width: "10em",
            filters: getUniqueValuesComma(promotionsData, 'sbu') as FilterDropdownProps['filters'],
            filterIcon: filtered => <FilterFilled style={{ color: filtered ?  '#fff':'#808080' }} />, 
            sorter: (a: IPromotion, b: IPromotion) => a.sbu.join(',').localeCompare(b.sbu.join(',')),
            onCell: (_record, _rowIndex) => {
                return {
                    style: { padding: "8px" }
                };
            },
            render: (_, { sbu }) => (
                sbu.join(', ')
            ),
            onFilter: (value, record) => record.sbu.indexOf(String(value)) === 0,
        },
        {
            title: "Department",
            dataIndex: "department",
            key: "department",
            width: "10em",
            filters: getUniqueValuesComma(promotionsData, 'department') as FilterDropdownProps['filters'],
            filterIcon: filtered => <FilterFilled style={{ color: filtered ?  '#fff':'#808080' }} />,
            sorter: (a: IPromotion, b: IPromotion) => a.department.join(',').localeCompare(b.department.join(',')),
            onCell: (_record, _rowIndex) => {
                return {
                    style: { padding: "8px" }
                };
            },
            render: (_, { department }) => (
                department.join(',')
            ),
            onFilter: (value, record) => record.department.indexOf(String(value)) === 0,
        },
        {
            title: "Collection",
            dataIndex: "collection",
            key: "collection",
            width: "10em",
            filters: getUniqueValuesComma(promotionsData, 'collection') as FilterDropdownProps['filters'],
            filterIcon: filtered => <FilterFilled style={{ color: filtered ?  '#fff':'#808080' }} />,
            sorter: (a: IPromotion, b: IPromotion) => a.collection.localeCompare(b.collection),
            onCell: (_record, _rowIndex) => {
                return {
                    style: { padding: "8px" }
                };
            },
            onFilter: (value, record) => record.collection.indexOf(String(value)) === 0,
        },
        {
            title: "Price Changed",
            dataIndex: "price_change",
            key: "price_change",
            align: "center",
            width: "7em",
            filters: [
                { text: 'Change', value: true },
                { text: 'No change', value: false },
            ],
            filterIcon: filtered => <FilterFilled style={{ color: filtered ?  '#fff':'#808080' }} />,
            sorter: (a: IPromotion, b: IPromotion) => Number(a.price_change) - Number(b.price_change),
            onCell: (_record, _rowIndex) => {
                return {
                    style: { padding: "8px" }
                };
            },
            render: (_, { price_change }) => (
                price_change ? <CloseOutlined style={{ "color": "red" }} /> : <div />
            ),
            onFilter: (value, record) => record.price_change === value,
        },
        {
            title: "Promo Price",
            dataIndex: "promo_price",
            key: "promo_price",
            width: "10em",
            filters: getUniqueValuesComma(promotionsData, 'promo_price') as FilterDropdownProps['filters'],
            filterIcon: filtered => <FilterFilled style={{ color: filtered ?  '#fff':'#808080' }} />,
            sorter: (a: IPromotion, b: IPromotion) => a.promo_price.localeCompare(b.promo_price),
            onCell: (_record, _rowIndex) => {
                return {
                    style: { padding: "8px" }
                };
            },
            onFilter: (value, record) => record.promo_price.indexOf(String(value)) === 0,
        },
        {
            title: "Sign Kit",
            dataIndex: "sign_kit",
            key: "sign_kit",
            width: "10em",
            filters: getUniqueValuesComma(promotionsData, 'sign_kit') as FilterDropdownProps['filters'],
            filterIcon: filtered => <FilterFilled style={{ color: filtered ?  '#fff':'#808080' }} />,
            sorter: (a: IPromotion, b: IPromotion) => a.sign_kit.localeCompare(b.sign_kit),
            onCell: (_record, _rowIndex) => {
                return {
                    style: { padding: "8px" }
                };
            },
            render: (_, {alt_color_flag, pdf_flag,sign_kit}) => (
                <span style={{ color: (alt_color_flag && (!pdf_flag)) ? signKitColorsState.GenericAltColor : 'black' }}>
                {pdf_flag ? "PDF" : sign_kit} 
                </span>
            ),
            onFilter: (value, record) => record.sign_kit.indexOf(String(value)) === 0,
        },
        {
            title: "Alt Color",
            dataIndex: "is_alt_color",
            key: "is_alt_color",
            align: "center",
            width: "6em",
            filters: [
                { text: 'Active', value: true },
                { text: 'Inactive', value: false },
            ],
            filterIcon: filtered => <FilterFilled style={{ color: filtered ? '#fff' : '#808080' }} />,
            sorter: (a: IPromotion, b: IPromotion) => {
                if (a.alt_color_flag === b.alt_color_flag) return 0;
                return a.alt_color_flag ? 1 : -1;
            },
            onCell: (_record, _rowIndex) => {
                return {
                    style: { padding: "8px" }
                };
            },
            render: (_, { alt_color_flag, pdf_flag, id, stores }) => (
                readOnlyState.isReadOnly && alt_color_flag ? <CheckSquareOutlined /> :
                readOnlyState.isReadOnly && !alt_color_flag ? <BorderOutlined /> :
                pdf_flag ? (
                    <BorderOutlined style={{ color: 'gray', backgroundColor: 'lightgray', pointerEvents: 'none' }} />
                ) : (
                    (alt_color_flag && !pdf_flag) ? 
                    <CheckSquareOutlined onClick={() => genericAltSignClickHandler(id, alt_color_flag, stores)} /> :
                    <BorderOutlined onClick={() => genericAltSignClickHandler(id, alt_color_flag, stores)} />
                )
            ),
            onFilter: (value, record) => {
                try { 
                    if(record.alt_color_flag){
                        return value ===true;
                    }else{
                        return value ===false;
                    }
                } catch (error) {
                    return false;
                } 
            }
        },
        {
            title: "PDF Sign",
            dataIndex: "pdf_flag",
            key: "pdf_flag",
            align: "center",
            width: "5em",
            filters: [
                { text: 'Active', value: true },
                { text: 'Inactive', value: false },
            ],
            filterIcon: filtered => <FilterFilled style={{ color: filtered ?  '#fff' :'#808080' }} />,
            sorter: (a: IPromotion, b: IPromotion) => {
                if (a.pdf_flag === b.pdf_flag) return 0;
                return a.pdf_flag ? 1 : -1;
            },
            onCell: (_record, _rowIndex) => {
                return {
                    style: { padding: "8px" }
                };
            },
            render: (_, { id, pdf_flag, stores, alt_color_flag}) => (
                readOnlyState.isReadOnly && pdf_flag ? <CheckSquareOutlined /> :
                readOnlyState.isReadOnly && !pdf_flag ? <BorderOutlined /> :
                pdf_flag ? <CheckSquareOutlined onClick={() => { pdfSignClickHandler(id, pdf_flag, stores, alt_color_flag) }} /> :
                <BorderOutlined onClick={() => { pdfSignClickHandler(id, pdf_flag, stores, alt_color_flag) }} />

            ),
            onFilter: (value, record) => {
                try { 
                    if(record.sign_kit ==="PDF"){
                        return value ===true;
                    }else{
                        return value ===false;
                    }
                } catch (error) {
                    return false;
                } 
                    
                
            }
        },
        {
            title: "Comments",
            dataIndex: "comments",
            key: "comments",
            width: "15em",
            sorter: (a: IPromotion, b: IPromotion) => a.comments.localeCompare(b.comments),
            render: (_, { id, comments, stores }) => (
                <Comments priceChangeId={id} comments={comments} commentsSubmitHandler={commentsSubmitHandler} stores={stores} />
            )
        },
        {
            title: "Stores",
            dataIndex: "stores",
            key: "stores",
            width: "5em",
            filters: getUniqueValuesComma(promotionsData, 'stores') as FilterDropdownProps['filters'],
            filterIcon: filtered => <FilterFilled style={{ color: filtered ?  '#fff':'#808080' }} />,
            onCell: (_record, _rowIndex) => {
                return {
                    style: { padding: "8px" }
                };
            },
            render: (_, { stores }) => (
                <StoresCell stores={stores} />
            ),
            onFilter: (value, record) => record.stores.includes(String(value)),
        }
    ]

    const paginationConfig = {
        'defaultPageSize': 100,
        'showSizeChanger': true,
        'pageSizeOptions': [10, 25, 50, 100],
        'showExpandColumn': true
    }


    return (
        <Content
            style={{
                padding: "8px"
            }}
        >
            <Col offset={0} span={24}>
                <Table
                    className='price-change-table'
                    dataSource={promotionsData}
                    columns={columns}
                    rowKey={(record) => record.base_id.toString()}
                    sticky
                    pagination={paginationConfig}
                />
            </Col>
        </Content>
    );
}

export default PromotionsTable;
// export {}